import { sha256 } from "ethers";

const BASE = 58;
const ALPHABET = "123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz";
const ADDRESS_PREFIX = "41";

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const getSiblings = (elem: HTMLElement): HTMLElement[] => {
  const siblings = [];
  let sibling = elem?.parentNode?.firstChild as HTMLElement;
  while (sibling) {
    if (sibling.nodeType === 1 && sibling !== elem) {
      siblings.push(sibling);
    }
    sibling = sibling.nextSibling as HTMLElement;
  }
  return siblings;
};

export const hexTorgb = (hex: string, opacity: number): string | undefined => {
  const h = hex.replace("#", "");
  // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
  const hh = h.match(new RegExp(`(.{${h.length / 3}})`, "g"));
  const rgba = [];
  if (!hh) return;
  for (let i = 0; i < hh.length; i += 1)
    rgba[i] = parseInt(hh[i].length === 1 ? hh[i] + hh[i] : hh[i], 16);

  if (typeof opacity !== "undefined") rgba.push(opacity);

  // eslint-disable-next-line consistent-return
  return `rgba(${rgba.join(",")})`;
};

export const flatDeep = (arr: any[], d = 1): any[] => {
  return d > 0
    ? arr.reduce((acc, val) => {
        return acc.concat(
          Array.isArray(val) ? flatDeep(val, d - 1) : val
        ) as any[];
      }, [])
    : arr.slice();
};

export const formatNumber = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const hasKey = (obj: any, key: string): boolean => {
  return !!Object.prototype.hasOwnProperty.call(obj, key);
};

export const generateDayWiseTimeSeries = (
  baseval: number,
  count: number,
  yrange: { min: any; max: any }
) => {
  let i = 0;
  let value = baseval;
  const series = [];
  while (i < count) {
    const y =
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([value, y]);
    value += 86400000;
    i += 1;
  }
  return series;
};

export const getExplorerUrl = (chain: string, stage = "dev") => {
  switch (chain) {
    case "polygon":
      return stage !== "prod"
        ? "https://mumbai.polygonscan.com"
        : "https://polygonscan.com";
    case "celo":
      return stage !== "prod"
        ? "https://explorer.celo.org/alfajores"
        : "https://explorer.celo.org/mainnet";
    case "optimism":
      return stage !== "prod"
        ? "https://goerli-optimism.etherscan.io"
        : "https://optimistic.etherscan.io";
    case "mainnet":
      return stage !== "prod"
        ? "https://sepolia.etherscan.io"
        : "https://etherscan.io";
    case "arbitrum":
      return stage !== "prod"
        ? "https://sepolia-explorer.arbitrum.io"
        : "https://arbiscan.io";
    case "tron":
      return stage !== "prod"
        ? "https://shasta.tronscan.org"
        : "https://tronscan.org";
    default:
      return "";
  }
};

const hexChar2byte = (c: string) => {
  let d;

  if (c >= "A" && c <= "F") d = c.charCodeAt(0) - "A".charCodeAt(0) + 10;
  else if (c >= "a" && c <= "f") d = c.charCodeAt(0) - "a".charCodeAt(0) + 10;
  else if (c >= "0" && c <= "9") d = c.charCodeAt(0) - "0".charCodeAt(0);

  if (typeof d === "number") return d;
  else throw new Error("The passed hex char is not a valid hex char");
};

const isHexChar = (c: string) => {
  if (
    (c >= "A" && c <= "F") ||
    (c >= "a" && c <= "f") ||
    (c >= "0" && c <= "9")
  ) {
    return 1;
  }

  return 0;
};

const hexStr2byteArray = (str: string, strict = false) => {
  if (typeof str !== "string")
    throw new Error("The passed string is not a string");

  let len = str.length;

  if (strict) {
    if (len % 2) {
      str = `0${str}`;
      len++;
    }
  }
  const byteArray = [];
  let d = 0;
  let j = 0;
  let k = 0;

  for (let i = 0; i < len; i++) {
    const c = str.charAt(i);

    if (isHexChar(c)) {
      d <<= 4;
      d += hexChar2byte(c);
      j++;

      if (0 === j % 2) {
        byteArray[k++] = d;
        d = 0;
      }
    } else throw new Error("The passed hex char is not a valid hex string");
  }

  return byteArray;
};

const encode58 = (buffer: number[]) => {
  if (buffer.length === 0) return "";

  let i;
  let j;

  const digits = [0];

  for (i = 0; i < buffer.length; i++) {
    for (j = 0; j < digits.length; j++) digits[j] <<= 8;

    digits[0] += buffer[i];
    let carry = 0;

    for (j = 0; j < digits.length; ++j) {
      digits[j] += carry;
      carry = (digits[j] / BASE) | 0;
      digits[j] %= BASE;
    }

    while (carry) {
      digits.push(carry % BASE);
      carry = (carry / BASE) | 0;
    }
  }

  for (i = 0; buffer[i] === 0 && i < buffer.length - 1; i++) digits.push(0);

  return digits
    .reverse()
    .map((digit) => ALPHABET[digit])
    .join("");
};

const byteArray2hexStr = (byteArray: number[]) => {
  let str = "";

  for (let i = 0; i < byteArray.length; i++) str += byte2hexStr(byteArray[i]);

  return str;
};

const byte2hexStr = (byte: number) => {
  if (typeof byte !== "number") throw new Error("Input must be a number");

  if (byte < 0 || byte > 255) throw new Error("Input must be a byte");

  const hexByteMap = "0123456789ABCDEF";

  let str = "";
  str += hexByteMap.charAt(byte >> 4);
  str += hexByteMap.charAt(byte & 0x0f);

  return str;
};
const SHA256 = (msgBytes: number[]) => {
  const msgHex = byteArray2hexStr(msgBytes);
  const hashHex = sha256("0x" + msgHex).replace(/^0x/, "");
  return hexStr2byteArray(hashHex);
};

const getBase58CheckAddress = (addressBytes: number[]) => {
  const hash0 = SHA256(addressBytes);
  const hash1 = SHA256(hash0);

  let checkSum = hash1.slice(0, 4);
  checkSum = addressBytes.concat(checkSum);

  return encode58(checkSum);
};

const isHex = (string: string) => {
  return (
    typeof string === "string" &&
    !isNaN(parseInt(string, 16)) &&
    /^(0x|)[a-fA-F0-9]+$/.test(string)
  );
};

export const encodeTronAddress = (address: string) => {
  if (!isHex(address)) {
    return address;
  }
  return getBase58CheckAddress(
    hexStr2byteArray(address.replace(/^0x/, ADDRESS_PREFIX))
  );
};

export const formatCurrency = (currency: string, chain: string) => {
  return `${currency.toUpperCase()}_${chain.toUpperCase()}`;
};

const decode58 = (address: string) => {
  const digits = [0];

  for (let i = 0; i < address.length; i++) {
    const char = address[i];
    const index = ALPHABET.indexOf(char);
    if (index === -1) {
      throw new Error("Invalid character in Base58 string.");
    }

    for (let j = 0; j < digits.length; j++) {
      digits[j] *= BASE;
    }

    digits[0] += index;

    let carry = 0;
    for (let j = 0; j < digits.length; ++j) {
      digits[j] += carry;
      carry = (digits[j] / 256) | 0;
      digits[j] %= 256;
    }

    while (carry) {
      digits.push(carry % 256);
      carry = (carry / 256) | 0;
    }
  }

  for (let i = 0; address[i] === "1" && i < address.length - 1; i++) {
    digits.push(0);
  }

  return digits.reverse();
};

export const getTronAddressFromBase58 = (base58Address: string) => {
  const addressBytes = decode58(base58Address);
  if (addressBytes.length <= 4) {
    throw new Error("Invalid Tron address.");
  }

  const address = addressBytes.slice(0, -4);
  const checksum = addressBytes.slice(-4);

  const hash0 = SHA256(address);
  const hash1 = SHA256(hash0);
  const calculatedChecksum = hash1.slice(0, 4);

  if (
    calculatedChecksum[0] !== checksum[0] ||
    calculatedChecksum[1] !== checksum[1] ||
    calculatedChecksum[2] !== checksum[2] ||
    calculatedChecksum[3] !== checksum[3]
  ) {
    throw new Error("Invalid Tron address checksum.");
  }

  const hexAddress = byteArray2hexStr(address);
  return `0x${hexAddress.slice(2)}`;
};
