import { Suspense, lazy, useEffect, useState, useMemo } from "react";
import {
  getCurrentUser,
  fetchUserAttributes,
  fetchAuthSession,
} from "aws-amplify/auth";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import Preloader from "./components/preloader";
import Layout from "./layouts";

import { RequireAuth } from "./RequireAuth";
import { AuthUserContext } from "./provider";
import { IndexBooster } from "./libs/index-booster";

// Classic Pages
const Analytics = lazy(() => import("./pages/analytics"));
const RemAnalytics = lazy(() => import("./pages/remittance-analytics"));
const Transactions = lazy(() => import("./pages/transactions"));
const LiquidityPage = lazy(() => import("./pages/liquidity"));
const Alerts = lazy(() => import("./pages/alerts"));
const Alert = lazy(() => import("./pages/alert"));
const Transaction = lazy(() => import("./pages/transaction"));
const Merchants = lazy(() => import("./pages/merchants"));
const Corporates = lazy(() => import("./pages/corporates"));
const Corporate = lazy(() => import("./pages/corporate"));
const Users = lazy(() => import("./pages/users"));
const User = lazy(() => import("./pages/user"));
const Parameters = lazy(() => import("./pages/parameters"));
const SignIn = lazy(() => import("./pages/signin"));
const SignUp = lazy(() => import("./pages/signup"));
const UpdatePassword = lazy(() => import("./pages/update-password"));
const VerifyAccount = lazy(() => import("./pages/verify-account"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Error500 = lazy(() => import("./pages/error-500"));
const Error503 = lazy(() => import("./pages/error-503"));
const Error505 = lazy(() => import("./pages/error-505"));

const App = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [loadedAuth, setLoadedAuth] = useState<{ status: boolean; data: any }>({
    status: false,
    data: null,
  });

  const checkAuth = async () => {
    try {
      const userData = await getCurrentUser();
      const userAttributes = await fetchUserAttributes();
      const authSession = await fetchAuthSession();
      setLoadedAuth({
        status: true,
        data: {
          ...userData,
          attributes: userAttributes,
          groups:
            authSession.tokens?.accessToken.payload["cognito:groups"] ?? [],
        },
      });
    } catch (err) {
      setLoadedAuth({ status: true, data: null });
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  const memoedAuth = useMemo(
    () => ({ loadedAuth, setLoadedAuth }),
    [loadedAuth]
  );

  if (!loadedAuth?.status) {
    return <Preloader />;
  }

  return (
    <>
      <Suspense fallback={<Preloader />}>
        <AuthUserContext.Provider value={memoedAuth}>
          <Router>
            <IndexBooster />
            <Routes>
              <Route
                element={
                  <Layout>
                    <Outlet />
                  </Layout>
                }
              >
                {/* Authentication Routes */}
                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/verify-account" element={<VerifyAccount />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                  path="/update-password"
                  element={
                    <RequireAuth>
                      <UpdatePassword />
                    </RequireAuth>
                  }
                />
                {/* Error Routes */}
                <Route path="/error-500" element={<Error500 />} />
                <Route path="/error-503" element={<Error503 />} />
                <Route path="/error-505" element={<Error505 />} />
                <Route path="/error-404" element={<ErrorNotFound />} />
                Dashboard Routes
                <Route
                  path="/"
                  element={
                    <RequireAuth>
                      <Analytics />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/remittance-analytics"
                  element={
                    <RequireAuth>
                      <RemAnalytics />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/liquidity"
                  element={
                    <RequireAuth>
                      <LiquidityPage />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/alerts/risk"
                  element={
                    <RequireAuth>
                      <Alerts category="Risk" />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/alerts/operation"
                  element={
                    <RequireAuth>
                      <Alerts category="Operation" />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/alerts/treasury"
                  element={
                    <RequireAuth>
                      <Alerts category="Treasury" />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/alert/:uuid"
                  element={
                    <RequireAuth>
                      <Alert />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/transactions"
                  element={
                    <RequireAuth>
                      <Transactions />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/transaction/:uuid"
                  element={
                    <RequireAuth>
                      <Transaction />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/merchants"
                  element={
                    <RequireAuth>
                      <Merchants />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/corporates"
                  element={
                    <RequireAuth>
                      <Corporates />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/corporate/:uuid"
                  element={
                    <RequireAuth>
                      <Corporate />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/users"
                  element={
                    <RequireAuth>
                      <Users />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/user/:uuid"
                  element={
                    <RequireAuth>
                      <User />
                    </RequireAuth>
                  }
                />
                <Route
                  path="/parameters"
                  element={
                    <RequireAuth>
                      <Parameters />
                    </RequireAuth>
                  }
                />
              </Route>

              {/* 404 Page Route */}
              <Route
                element={
                  <Layout>
                    <Outlet />
                  </Layout>
                }
              >
                <Route path="*" element={<ErrorNotFound />} />
              </Route>
            </Routes>
          </Router>
        </AuthUserContext.Provider>
      </Suspense>
    </>
  );
};

export default App;
